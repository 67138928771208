import React from "react";
import * as Layout from "components/layout";
import { Link, graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import Image from "components/image";
import { Section, Button, Container, Row } from "components/ui";
import { PostTile } from "components/blog";
import { GetStartedSection } from "components/sections";
import tw from "tailwind.macro";
import styled from "styled-components";
import { theme } from "../../tailwind.config";
import "styles/prism-twilight.css";
import "styles/pages/markdown.scss";
import BlogCta from "components/ui/cta/BlogCta";
import Tip from "components/ui/Tip";
import Toc from "components/ui/Toc";
import { Generic, JSONLD } from "react-structured-data";
import * as Ui from "components/ui"

// TODO: we should get this from gatsby but our version is too old
function getLastDateOfPreviousWeek() {
  const now = new Date();
  // Set to the last day of the previous week
  const dayOfWeek = now.getDay(); // Get the current day of the week (0-6, where 0 is Sunday)
  const lastSunday = new Date(now);
  lastSunday.setDate(now.getDate() - dayOfWeek - 1); // Go back to the previous Sunday
  const lastDayOfPreviousWeek = new Date(lastSunday);

  // Format the date as YYYY-MM-DD
  const year = lastDayOfPreviousWeek.getFullYear();
  const month = String(lastDayOfPreviousWeek.getMonth() + 1).padStart(2, '0');
  const day = String(lastDayOfPreviousWeek.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

const lastUpdateDate = getLastDateOfPreviousWeek();

const linksToDocs = {
  "amazon aws" : [
    {'label': 's3 documentation' , 'link': "https://docs.aws.amazon.com/general/latest/gr/s3.html"},
    {'label': 'ec2 documentation', 'link': "https://docs.aws.amazon.com/general/latest/gr/s3.html"}
  ],
  "amazon lightsail" : [
    {'label': 'lightsail documentation', 'link': "https://docs.aws.amazon.com/lightsail/latest/userguide/understanding-regions-and-availability-zones-in-amazon-lightsail.html"}
  ],
  "backblaze" : [
    {'label': 'API documentation', 'link': "https://www.backblaze.com/docs/cloud-storage-apis"},
  ],
  "digitalocean" : [
    {'label': 'Spaces documentation', 'link': "https://docs.digitalocean.com/products/spaces/details/availability/"},
    {'label': 'Droplet documentation', 'link': "https://docs.digitalocean.com/platform/regional-availability/"},
  ],
  "exoscale" : [
    {'label': 'Exoscale documentation', 'link': "https://community.exoscale.com/documentation/platform/exoscale-datacenter-zones/"},
  ],
  "google cloud" : [
    {'label': 'Google Cloud storage documentation', 'link': "https://cloud.google.com/storage/docs/locations/"},
    {'label': 'Google Cloud compute documentation', 'link': "https://cloud.google.com/compute/docs/regions-zones"},
  ],
  "hetzner" : [
    {'label': 'Hetzner Cloud documentation', 'link': "https://docs.hetzner.com/cloud/general/locations/"},
  ],
  "linode" : [
    {'label': 'Linode storage documentation', 'link': "https://www.linode.com/docs/products/storage/object-storage/"},
    {'label': 'Linode compute documentation', 'link': "https://api.linode.com/v4/regions"},
  ],
  "outscale" : [
    {'label': 'Outscale documentation', 'link': "https://docs.outscale.com/en/userguide/Regions-Endpoints-and-Subregions-Reference.html"},
  ],
  "storj" : [
    {'label': 'Storj documentation', 'link': "https://docs.storj.io/"},
  ],
  "vultr" : [
    {'label': 'Vultr api', 'link': "https://www.vultr.com/api/"},
  ],
  "wasabi" : [
    {'label': 'Wasabi documentation', 'link': "https://wasabi.com/company/storage-regions"},
  ],
}

const getDocsByKey = function (key) {
  return linksToDocs.hasOwnProperty(key) ? linksToDocs[key] : [{'label': 'documentation', 'link':'https://docs.simplebackups.com'}];
}

export default function Regions({ pageContext }) {

  const { cloudProviderRegions } = pageContext;
  return (
    <Layout.Layout>
      <Layout.SEO
        title="Cloud Provider Regions Index - SimpleBackups"
        description="Cloud Provider Regions is based on our internal tool that retrieves regions supported by all cloud providers we support at SimpleBackups. This list is automatically updated whenever we detect a change in the cloud provider's API."
      />
      <Layout.HeroEmpty />
      <Section>
        <Container>
          <main className="xl:grid grid-cols-8 gap-20 mt-20 pb-20">
            <aside className="hidden xl:block relative col-span-2 border-r pt-0">
              <div className="sticky top-10 ">
                <p className="uppercase text-xs text-gray-500 tracking-wide">Cloud Providers</p>
                <menu className="text-sm ml-2 pl-0 space-y-1">
                  {Object.keys(cloudProviderRegions).map((provider) => {
                    return (
                      <a href={`#${provider.toLowerCase()}`}
                         className="list-none hover:bg-gray-100 transition p-1.5 pl-3 gap-3 flex items-center "
                         key={`aside-${provider.toLowerCase()}`}>
                        <i className="fa fas fa-caret-right text-gray-400 w-4 text-sm"></i>
                        <span className="text-gray-600">{provider}</span>
                      </a>
                  );
                  })}
                </menu>
                <menu className="text-sm ml-0 pl-0 space-y-1 mt-12">
                  <a href="mailto:hello@simplebackups.com?subject=cloud-provider-regions-support" className="list-none hover:bg-gray-100 transition p-1.5 pl-3 gap-3 flex items-center ">
                    <i className="fal fa-comment text-xs w-4 text-gray-400"></i><span>Contact Support</span>
                  </a>
                  <a href="mailto:hello@simplebackups.com?subject=Request new provider (regions)" className="list-none hover:bg-gray-100 transition p-1.5 pl-3 gap-3 flex items-center ">
                    <i className="fal fa-plus text-xs w-4 text-gray-400"></i><span>Request other providers</span>
                  </a>
                  <a href="mailto:hello@simplebackups.com?subject=Request API access (regions)" className="list-none hover:bg-gray-100 transition p-1.5 pl-3 gap-3 flex items-center ">
                    <i className="fal fa-code text-xs w-4 text-gray-400"></i><span>Access API</span>
                  </a>
                </menu>
              </div>
            </aside>

            <article className="col-span-6 w-full prose max-w-3xl">
              <Ui.Typography tag="h1">Cloud Provider Regions Index</Ui.Typography>
              <p className="border-b border-gray-100 pb-8">Cloud Provider Regions is based on our internal tool that retrieves regions supported by all cloud providers we support at SimpleBackups.
              This regions are refreshed whenever we detect a change in the cloud provider's API.</p>
              {Object.keys(cloudProviderRegions).map((provider) => (
                <section key={provider} className="mt-12">
                  <h2 id={provider.toLowerCase()}>{provider} Regions</h2>
                  <div className="bg-gray-50 border border-gray-100 p-3 flex items-start rounded-md">
                      <span className="w-8">ℹ️</span>
                      <div className="text-md p-0 m-0">
                        You can find all regions supported by {provider} on:
                        <span className="inline-bloc ml-1">
                        {getDocsByKey(provider.toLowerCase()).map((elt, index) => (
                          <span>{index > 0 && ', '}
                          <a href={elt.link} className="" target="_blank">{elt.label}</a>
                          </span>
                        ))}
                        </span>.<br/>
                        <i className="text-sm">This list was last updated on {lastUpdateDate}.</i>
                      </div>

                  </div>
                  <div>
                    {Object.keys(cloudProviderRegions[provider]).map(
                      (section) =>
                        cloudProviderRegions[provider][section] ===
                        null ? null : (
                          <div key={section}>
                            <h3>
                              {provider} {section} Regions
                            </h3>
                            <pre className="w-full">
                              {JSON.stringify(
                                cloudProviderRegions[provider][section],
                                null,
                                2
                              )}
                            </pre>
                          </div>
                        )
                    )}
                  </div>
                </section>
              ))}
            </article>
          </main>
        </Container>
      </Section>
      <GetStartedSection />
    </Layout.Layout>
  );
}
